.title_wrap{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.check_list_wrap{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap:5px;
  padding:0px 10px;
}
.check_item{
  background: #e5e5e5;
  font-size: 16px;
  padding:0px 10px;
  line-height: 30px;
  cursor: pointer;
  position: relative;
}
.check_item.active{
  background: #063e88;
  color:#fff;
}
.item_title_span{
  margin-left:10px;
}

.item_right{
  position: absolute;
  display: inline-block;
  top:0px;
  right: 5px;
  line-height: 30px;
}

.item_discount_input{
  text-align: right;
  border:1px solid #c7c7c7;
  width: 60px;
  color:#000000;
  background-color: transparent;
  margin-left: 5px;
  height: 25px;
  padding:0px 2px;
}
.check_item.active .item_discount_input{
  color:#ffffff;
}